import { css } from 'styled-components';

export const breakpoints = {
  md: 1032,
};


export const respondTo = Object.keys(breakpoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
    return acc;
  },
  {},
);

// Example Usage:

// export const ExampleComponent = styled.div`
//   background-color: lime;
//
//   ${respondTo.sm`
//     background-color: aqua;
// `  }
// `;