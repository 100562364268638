import { cancerTypes } from '../../../util/data/cancerTypes';
import { indications } from '../../../util/data/indications';

let cancerTypeOrder = [1,2];

export const getCancerTypeIdFromIndicationId = (indicationID) => {
    let cancerType = Object.values(cancerTypes).find(cancerType => cancerType.indications.includes(indicationID));
    return cancerType.id;
}

export const createIndicationListEntryByCancerType = (cancerTypeId) => {
    let items = cancerTypes[cancerTypeId].indications.map(
        indicationID => indications[indicationID]
    );

    return {
        id: cancerTypeId,
        modifierAndName: `${cancerTypes[cancerTypeId].modifier} ${cancerTypes[cancerTypeId].name}`,
        items: items,
        indicationIds: cancerTypes[cancerTypeId].indications //used for filtering
    };
}



//when we launched, the IndicationPreviewDrawer was set to always be global. The TNBC 522 launch meant we had to create a filtered version of every aspect of the Safety/Indications, so this is a hacky workaround done in a few hours. Can probably be wholly rethought.
export const createIndicationPreviewText = (indicationId) => {

    let flatIndicationList = [];

    let cancerTypeList = (indicationId) ? [getCancerTypeIdFromIndicationId(indicationId)] : cancerTypeOrder;
    //create an ordered, flat list of JUST indication statements, without the cancer type groups:
    cancerTypeList.map(cancerTypeId => {
        cancerTypes[cancerTypeId].indications.map(
            indicationID => flatIndicationList.push(indications[indicationID].statement)
        )}
    );

    return flatIndicationList.slice(0,2).join('');
}

export const indicationsData = cancerTypeOrder.map(cancerTypeId => createIndicationListEntryByCancerType(cancerTypeId));

export { default as globalSafety } from './globalSafety';

