import React from 'react';
import styled from 'styled-components';
import { Body } from '../../util/typography';
import * as colors from '../../util/colors';
import { rgba } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import externalIcon from '../../assets/utility/icons/icon-button-arrow-external.svg';
import {
    closeGeneralModal,
    allowScrolling,
} from '../../store/actions';
import { GENERAL_MODAL_OVERLAY } from '../../constants/z-index-map';
import Button from '../Button';
import closeIcon from '../../assets/utility/icons/icon-close-x.svg';
import { respondTo } from '../../util/breakpoints';

const ModalOverlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: ${rgba(colors.ink, 0.8)};
    z-index: ${GENERAL_MODAL_OVERLAY};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ModalContainer = styled.div`
    padding: 64px 15px;
    border-radius: 10px;
    background: ${colors.white};
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    width: calc(100% - 40px);
    img.userIcon {
        width: 18px;
        height: 18px;
    }
    ${respondTo.md`
        padding: 64px 40px;
        width: min(100%, 802px);
    `}
`;

const ContentText = styled(Body)`
    color: ${colors.charcoal};
    text-align: center;
`;

const CloseButton = styled.button`
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    flex-direction: column;
    color: ${colors.greenDark};
    gap: 4px;
    align-items: center;
    background: none;
    border: 0;
    font-family: Arial;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
    img {
        width: 17px;
        height: 17px;
        filter: invert(36%) sepia(25%) saturate(900%) hue-rotate(82deg) brightness(94%) contrast(96%);
    }
`;

const ButtonContainer = styled.button`
    background: none;
    border: 0;
    padding: 0;
`;

const ExternalModal = ({ general_modal_ref }) => {
    const generalModalOpen = useSelector(state => state.generalModalOpen);
    const dispatch = useDispatch();

    const clickHandler = () => {
        dispatch(closeGeneralModal());
        dispatch(allowScrolling());
    };

    return (
        <>
            {generalModalOpen && (
                <ModalOverlay ref={general_modal_ref}>
                    <ModalContainer>
                        <ContentText>You will now be leaving this site and will be redirected to the Merck Medical Portal. The Merck Medical Portal is for health care professionals in the US and its territories only.</ContentText>
                        <ButtonContainer onClick={clickHandler}>
                            <Button type='primary-dark' target='_blank' to='https://www.merckmedicalportal.com/s/medical-information?NcIWHmCWpnBF%2B3JmzvxEPNr6IDT35eVLkzQzm9Ywi25%2FJ8TaCmDhQs7kCYaLx7Hb' icon={externalIcon}>Continue</Button>
                        </ButtonContainer>
                        <CloseButton onClick={clickHandler}>
                            <img src={closeIcon} alt='' />
                            CLOSE
                        </CloseButton>
                    </ModalContainer>
                </ModalOverlay>
            )}
        </>
    );
};

export default ExternalModal;
