import React from 'react';
import styled from 'styled-components';
import { PAGE_HEADER } from '../../constants/z-index-map';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { H1Style, H2Style } from '../../util/typography';

const PageHeaderContainer = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${({type}) => type === 'keytruda' ? `linear-gradient(90deg, ${colors.brandGreen} 39.06%, ${colors.greenLight} 100%)` : `linear-gradient(96deg, ${colors.mulberry} 47.42%, ${colors.mulberryLight} 100%);` } ;
    position: relative;
    transition: transform 200ms ease-in-out;
    transform: translateY(0);
    z-index: ${PAGE_HEADER};
    ${({hide}) => hide && `
        transform: translateY(-100%);
    `}
    ${({noBorder}) => !noBorder && `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 10px;
            background: linear-gradient(96deg, ${colors.mulberry} 47.42%, ${colors.mulberryLight} 100%);
        }
    `}
`;

const PageHeaderContent = styled.div`
    padding: 20px 15px 30px 15px;
    display: flex; 
    gap: 15px;
    max-width: 1032px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${respondTo.md`
        gap: 20px;
        padding: 30px 30px 40px 30px;
    `};
`;

const PageHeaderIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    ${respondTo.md`
        width: 70px;
        height: 70px;
    `};
`;

const PageHeaderTitle = styled.h2`
    ${H1Style};
    ${respondTo.md`
        ${H2Style};
    `};
    color: ${colors.white};
`;

const PageHeader = ({name, icon, iconAlt = '', type='keytruda', noBorder=false, hide}) => {
    return (
        <PageHeaderContainer type={type} noBorder={noBorder} hide={hide}>
            <PageHeaderContent>
                <PageHeaderTitle>{name}</PageHeaderTitle>
                {icon && <PageHeaderIcon src={icon} alt={iconAlt}/>}
            </PageHeaderContent>
        </PageHeaderContainer>
)};
export default PageHeader;