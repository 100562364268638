const globalSafetyList = {'keytruda': [
    {
        name: 'Severe and Fatal Immune-Mediated Adverse Reactions',
        items: [
            'KEYTRUDA is a monoclonal antibody that belongs to a class of drugs that bind to either the programmed death receptor-1 (PD&#8288;-&#8288;1) or the programmed death ligand 1 (PD&#8288;-&#8288;L1), blocking the PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 pathway, thereby removing inhibition of the immune response, potentially breaking peripheral tolerance and inducing immune-mediated adverse reactions. Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or tissue, can affect more than one body system simultaneously, and can occur at any time after starting treatment or after discontinuation of treatment. Important immune-mediated adverse reactions listed here may not include all possible severe and fatal immune-mediated adverse reactions.',

            'Monitor patients closely for symptoms and signs that may be clinical manifestations of underlying immune-mediated adverse reactions. Early identification and management are essential to ensure safe use of anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Evaluate liver enzymes, creatinine, and thyroid function at baseline and periodically during treatment. In cases of suspected immune-mediated adverse reactions, initiate appropriate workup to exclude alternative etiologies, including infection. Institute medical management promptly, including specialty consultation as appropriate.',

            'Withhold or permanently discontinue KEYTRUDA depending on severity of the immune-mediated adverse reaction. In general, if KEYTRUDA requires interruption or discontinuation, administer systemic corticosteroid therapy (1 to 2&nbsp;mg/kg/day prednisone or equivalent) until improvement to Grade 1 or less. Upon improvement to Grade 1 or less, initiate corticosteroid taper and continue to taper over at least 1 month. Consider administration of other systemic immunosuppressants in patients whose adverse reactions are not controlled with corticosteroid therapy.'
        ],
    },
    {
        name: '<em>Immune-Mediated Pneumonitis</em>',
        items: [
            'KEYTRUDA can cause immune-mediated pneumonitis. The incidence is higher in patients who have received prior thoracic radiation. Immune-mediated pneumonitis occurred in 3.4% (94/2799) of patients receiving KEYTRUDA, including fatal (0.1%), Grade 4 (0.3%), Grade 3 (0.9%), and Grade 2 (1.3%) reactions. Systemic corticosteroids were required in 67% (63/94) of patients. Pneumonitis led to permanent discontinuation of KEYTRUDA in 1.3% (36) and withholding in 0.9% (26) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 23% had recurrence. Pneumonitis resolved in 59% of the 94 patients.'
        ],
    },
    {
        name: '<em>Immune-Mediated Colitis</em>',
        items: [
            'KEYTRUDA can cause immune-mediated colitis, which may present with diarrhea. Cytomegalovirus infection/reactivation has been reported in patients with corticosteroid-refractory immune-mediated colitis. In cases of corticosteroid-refractory colitis, consider repeating infectious workup to exclude alternative etiologies. Immune-mediated colitis occurred in 1.7% (48/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (1.1%), and Grade 2 (0.4%) reactions. Systemic corticosteroids were required in 69% (33/48); additional immunosuppressant therapy was required in 4.2% of patients. Colitis led to permanent discontinuation of KEYTRUDA in 0.5% (15) and withholding in 0.5% (13) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 23% had recurrence. Colitis resolved in 85% of the 48 patients.'
        ],
    },
    {
        name:
            '<em>Hepatotoxicity and Immune-Mediated Hepatitis</em>',
        items: [
            {
                name: 'KEYTRUDA as a Single Agent',
                items: [
                    'KEYTRUDA can cause immune-mediated hepatitis. Immune-mediated hepatitis occurred in 0.7% (19/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.4%), and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 68% (13/19) of patients; additional immunosuppressant therapy was required in 11% of patients. Hepatitis led to permanent discontinuation of KEYTRUDA in 0.2% (6) and withholding in 0.3% (9) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, none had recurrence. Hepatitis resolved in 79% of the 19 patients.'
                ]
            }
        ],
    },
    {
        name: '<em>Immune-Mediated Endocrinopathies</em>',
        items: [
            {
                name: 'Adrenal Insufficiency',
                items: [
                    'KEYTRUDA can cause primary or secondary adrenal insufficiency. For Grade 2 or higher, initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold KEYTRUDA depending on severity. Adrenal insufficiency occurred in 0.8% (22/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.3%), and Grade 2 (0.3%) reactions. Systemic corticosteroids were required in 77% (17/22) of patients; of these, the majority remained on systemic corticosteroids. Adrenal insufficiency led to permanent discontinuation of KEYTRUDA in <0.1% (1) and withholding in 0.3% (8) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            },
            {
                name: 'Hypophysitis',
                items: [
                    'KEYTRUDA can cause immune-mediated hypophysitis. Hypophysitis can present with acute symptoms associated with mass effect such as headache, photophobia, or visual field defects. Hypophysitis can cause hypopituitarism. Initiate hormone replacement as indicated. Withhold or permanently discontinue KEYTRUDA depending on severity. Hypophysitis occurred in 0.6% (17/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.3%), and Grade 2 (0.2%) reactions. Systemic corticosteroids were required in 94% (16/17) of patients; of these, the majority remained on systemic corticosteroids. Hypophysitis led to permanent discontinuation of KEYTRUDA in 0.1% (4) and withholding in 0.3% (7) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            },
            {
                name: 'Thyroid Disorders',
                items: [
                    'KEYTRUDA can cause immune-mediated thyroid disorders. Thyroiditis can present with or without endocrinopathy. Hypothyroidism can follow hyperthyroidism. Initiate hormone replacement for hypothyroidism or institute medical management of hyperthyroidism as clinically indicated. Withhold or permanently discontinue KEYTRUDA depending on severity. Thyroiditis occurred in 0.6% (16/2799) of patients receiving KEYTRUDA, including Grade 2 (0.3%). None discontinued, but KEYTRUDA was withheld in <0.1% (1) of patients.',

                    'Hyperthyroidism occurred in 3.4% (96/2799) of patients receiving KEYTRUDA, including Grade 3 (0.1%) and Grade 2 (0.8%). It led to permanent discontinuation of KEYTRUDA in <0.1% (2) and withholding in 0.3% (7) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement. Hypothyroidism occurred in 8% (237/2799) of patients receiving KEYTRUDA, including Grade 3 (0.1%) and Grade 2 (6.2%). It led to permanent discontinuation of KEYTRUDA in <0.1% (1) and withholding in 0.5% (14) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement. The majority of patients with hypothyroidism required long-term thyroid hormone replacement.'
                ],
            },
            {
                name: 'Type 1 Diabetes Mellitus (DM), Which Can Present With Diabetic Ketoacidosis',
                items: [
                    'Monitor patients for hyperglycemia or other signs and symptoms of diabetes. Initiate treatment with insulin as clinically indicated. Withhold KEYTRUDA depending on severity. Type 1 DM occurred in 0.2% (6/2799) of patients receiving KEYTRUDA. It led to permanent discontinuation in <0.1% (1) and withholding of KEYTRUDA in <0.1% (1) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            }            
        ],
    },
    {
        name: '<em>Immune-Mediated Nephritis With Renal Dysfunction</em>',
        items: [
            'KEYTRUDA can cause immune-mediated nephritis. Immune-mediated nephritis occurred in 0.3% (9/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.1%), and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 89% (8/9) of patients. Nephritis led to permanent discontinuation of KEYTRUDA in 0.1% (3) and withholding in 0.1% (3) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, none had recurrence. Nephritis resolved in 56% of the 9 patients.',
        ],
    },
    {
        name: '<em>Immune-Mediated Dermatologic Adverse Reactions</em>',
        items: [
            'KEYTRUDA can cause immune-mediated rash or dermatitis. Exfoliative dermatitis, including Stevens-Johnson syndrome, drug rash with eosinophilia and systemic symptoms, and toxic epidermal necrolysis, has occurred with anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Topical emollients and/or topical corticosteroids may be adequate to treat mild to moderate nonexfoliative rashes. Withhold or permanently discontinue KEYTRUDA depending on severity. Immune-mediated dermatologic adverse reactions occurred in 1.4% (38/2799) of patients receiving KEYTRUDA, including Grade 3 (1%) and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 40% (15/38) of patients. These reactions led to permanent discontinuation in 0.1% (2) and withholding of KEYTRUDA in 0.6% (16) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 6% had recurrence. The reactions resolved in 79% of the 38 patients.'
        ],
    },
    {
        name: '<em>Other Immune-Mediated Adverse Reactions</em>',
        items: [
            `The following clinically significant immune-mediated adverse reactions occurred at an incidence of <1% (unless otherwise noted) in patients who received KEYTRUDA or were reported with the use of other anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Severe or fatal cases have been reported for some of these adverse reactions. <i>Cardiac/Vascular</i>: Myocarditis, pericarditis, vasculitis; <i>Nervous System</i>: Meningitis, encephalitis, myelitis and demyelination, myasthenic syndrome/myasthenia gravis (including exacerbation), Guillain-Barré syndrome, nerve paresis, autoimmune neuropathy; <i>Ocular</i>: Uveitis, iritis and other ocular inflammatory toxicities can occur. Some cases can be associated with retinal detachment. Various grades of visual impairment, including blindness, can occur. If uveitis occurs in combination with other immune-mediated adverse reactions, consider a Vogt-Koyanagi-Harada-like syndrome, as this may require treatment with systemic steroids to reduce the risk of permanent vision loss; <i>Gastrointestinal</i>: Pancreatitis, to include increases in serum amylase and lipase levels, gastritis, duodenitis; <i>Musculoskeletal and Connective Tissue</i>: Myositis/polymyositis, rhabdomyolysis (and associated sequelae, including renal failure), arthritis (1.5%), polymyalgia rheumatica; <i>Endocrine</i>: Hypoparathyroidism; <i>Hematologic/Immune</i>: Hemolytic anemia, aplastic anemia, hemophagocytic lymphohistiocytosis, systemic inflammatory response syndrome, histiocytic necrotizing lymphadenitis (Kikuchi lymphadenitis), sarcoidosis, immune thrombocytopenic purpura, solid organ transplant rejection, other transplant (including corneal graft) rejection.`
        ],
    },
    {
        name: 'Infusion-Related Reactions',
        items: [
            'KEYTRUDA can cause severe or life-threatening infusion-related reactions, including hypersensitivity and anaphylaxis, which have been reported in 0.2% of 2799 patients receiving KEYTRUDA. Monitor for signs and symptoms of infusion-related reactions. Interrupt or slow the rate of infusion for Grade 1 or Grade 2 reactions. For Grade 3 or Grade 4 reactions, stop infusion and permanently discontinue KEYTRUDA.'
        ],
    },
    {
        name: 'Complications of Allogeneic Hematopoietic Stem Cell Transplantation (HSCT)',
        items: [
            'Fatal and other serious complications can occur in patients who receive allogeneic HSCT before or after anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Transplant-related complications include hyperacute graft-versus-host disease (GVHD), acute and chronic GVHD, hepatic veno-occlusive disease after reduced intensity conditioning, and steroid-requiring febrile syndrome (without an identified infectious cause). These complications may occur despite intervening therapy between anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments and allogeneic HSCT. Follow patients closely for evidence of these complications and intervene promptly. Consider the benefit vs risks of using anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments prior to or after an allogeneic HSCT.'
        ],
    },
    {
        name: 'Increased Mortality in Patients With Multiple Myeloma',
        items: [
            'In trials in patients with multiple myeloma, the addition of KEYTRUDA to a thalidomide analogue plus dexamethasone resulted in increased mortality. Treatment of these patients with an anti&#8288;–&#8288;PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatment in this combination is not recommended outside of controlled trials.'
        ],
    },
    {
        name: 'Embryofetal Toxicity',
        items: [
            'Based on its mechanism of action, KEYTRUDA can cause fetal harm when administered to a pregnant woman. Advise women of this potential risk. In females of reproductive potential, verify pregnancy status prior to initiating KEYTRUDA and advise them to use effective contraception during treatment and for 4 months after the last dose.',
        ],
    },
    {
        name: 'Adverse Reactions',
        items: [
            `In KEYNOTE&#8288;-&#8288;581, when KEYTRUDA was administered in combination with LENVIMA to patients with advanced renal cell carcinoma (n=352), fatal adverse reactions occurred in 4.3% of patients. Serious adverse reactions occurred in 51% of patients; the most common (≥2%) were hemorrhagic events (5%), diarrhea (4%), hypertension, myocardial infarction, pneumonitis, and vomiting (3% each), acute kidney injury, adrenal insufficiency, dyspnea, and pneumonia (2% each).<br/><br/>Permanent discontinuation of KEYTRUDA, LENVIMA, or both due to an adverse reaction occurred in 37% of patients; 29% KEYTRUDA only, 26% LENVIMA only, and 13% both. The most common adverse reactions (≥2%) resulting in permanent discontinuation of KEYTRUDA, LENVIMA, or the combination were pneumonitis, myocardial infarction, hepatotoxicity, acute kidney injury, rash (3% each), and diarrhea (2%).<br/><br/>The most common adverse reactions (≥20%) observed with KEYTRUDA in combination with LENVIMA were fatigue (63%), diarrhea (62%), musculoskeletal disorders (58%), hypothyroidism (57%), hypertension (56%), stomatitis (43%), decreased appetite (41%), rash (37%), nausea (36%), weight loss, dysphonia and proteinuria (30% each), palmar-plantar erythrodysesthesia syndrome (29%), abdominal pain and hemorrhagic events (27% each), vomiting (26%), constipation and hepatotoxicity (25% each), headache (23%), and acute kidney injury (21%).`,

            `In KEYNOTE&#8288;-&#8288;775, when KEYTRUDA was administered in combination with LENVIMA to patients with advanced endometrial carcinoma that was pMMR or not MSI&#8288;-&#8288;H (n=342), fatal adverse reactions occurred in 4.7% of patients. Serious adverse reactions occurred in 50% of these patients; the most common (≥3%) were hypertension (4.4%) and urinary tract infections (3.2%).<br/><br/>
            Discontinuation of KEYTRUDA due to an adverse reaction occurred in 15% of these patients. The most common adverse reaction leading to discontinuation of KEYTRUDA (≥1%) was increased ALT (1.2%).<br/><br/>
            The most common adverse reactions for KEYTRUDA in combination with LENVIMA (reported in ≥20% patients) were hypothyroidism and hypertension (67% each), fatigue (58%), diarrhea (55%), musculoskeletal disorders (53%), nausea (49%), decreased appetite (44%), vomiting (37%), stomatitis (35%), abdominal pain and weight loss (34% each), urinary tract infections (31%), proteinuria (29%), constipation (27%), headache (26%), hemorrhagic events (25%), palmar&#8288;-&#8288;plantar erythrodysesthesia (23%), dysphonia (22%), and rash (20%).`,
        ],
    },
    {
        name: 'Lactation',
        items: [
            'Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment and for 4 months after the last dose.',
        ],
    },
],
'lenvima': [
    {
        name: 'Hypertension',
        items: [
            'In differentiated thyroid cancer (DTC), hypertension occurred in 73% of patients on LENVIMA (44% grade 3-4). In advanced renal cell carcinoma (RCC), hypertension occurred in 42% of patients on LENVIMA + everolimus (13% grade 3). Systolic blood pressure ≥160 mmHg occurred in 29% of patients, and 21% had diastolic blood pressure ≥100 mmHg. In unresectable hepatocellular carcinoma (HCC), hypertension occurred in 45% of LENVIMA-treated patients (24% grade 3). Grade 4 hypertension was not reported in HCC.',

            'Serious complications of poorly controlled hypertension have been reported. Control blood pressure prior to initiation. Monitor blood pressure after 1 week, then every 2 weeks for the first 2 months, and then at least monthly thereafter during treatment. Withhold and resume at reduced dose when hypertension is controlled or permanently discontinue based on severity.',

        ],
    },
    {
        name: 'Cardiac Dysfunction',
        items: [
            'Serious and fatal cardiac dysfunction can occur with LENVIMA. Across clinical trials in 799 patients with DTC, RCC, and HCC, grade 3 or higher cardiac dysfunction occurred in 3% of LENVIMA-treated patients. Monitor for clinical symptoms or signs of cardiac dysfunction. Withhold and resume at reduced dose upon recovery or permanently discontinue based on severity.'
        ],
    },
    {
        name: 'Arterial Thromboembolic Events',
        items: [
            'Among patients receiving LENVIMA or LENVIMA + everolimus, arterial thromboembolic events of any severity occurred in 2% of patients in RCC and HCC and 5% in DTC. Grade 3-5 arterial thromboembolic events ranged from 2% to 3% across all clinical trials.',
            
            'Among patients receiving LENVIMA with KEYTRUDA, arterial thrombotic events of any severity occurred in 5% of patients in CLEAR, including myocardial infarction (3.4%) and cerebrovascular accident (2.3%).',

            'Permanently discontinue following an arterial thrombotic event. The safety of resuming after an arterial thromboembolic event has not been established and LENVIMA has not been studied in patients who have had an arterial thromboembolic event within the previous 6 months.'
        ],
    },
    {
        name:
            'Hepatotoxicity',
        items: [
            'Across clinical studies enrolling 1,327 LENVIMA-treated patients with malignancies other than HCC, serious hepatic adverse reactions occurred in 1.4% of patients. Fatal events, including hepatic failure, acute hepatitis and hepatorenal syndrome, occurred in 0.5% of patients. In HCC, hepatic encephalopathy occurred in 8% of LENVIMA-treated patients (5% grade 3-5). Grade 3-5 hepatic failure occurred in 3% of LENVIMA-treated patients. 2% of patients discontinued LENVIMA due to hepatic encephalopathy and 1% discontinued due to hepatic failure.',

            'Monitor liver function prior to initiation, then every 2 weeks for the first 2 months, and at least monthly thereafter during treatment. Monitor patients with HCC closely for signs of hepatic failure, including hepatic encephalopathy. Withhold and resume at reduced dose upon recovery or permanently discontinue based on severity.'
        ],
    },
    {
        name: 'Renal Failure or Impairment',
        items: [
            'Serious including fatal renal failure or impairment can occur with LENVIMA. Renal impairment was reported in 14% and 7% of LENVIMA-treated patients in DTC and HCC, respectively. Grade 3-5 renal failure or impairment occurred in 3% of patients with DTC and 2% of patients with HCC, including 1 fatal event in each study. In RCC, renal impairment or renal failure was reported in 18% of LENVIMA + everolimus–treated patients (10% grade 3).',
            
            'Initiate prompt management of diarrhea or dehydration/hypovolemia. Withhold and resume at reduced dose upon recovery or permanently discontinue for renal failure or impairment based on severity.'
        ],
    },
    {
        name: 'Proteinuria',
        items: [
            'In DTC and HCC, proteinuria was reported in 34% and 26% of LENVIMA-treated patients, respectively. Grade 3 proteinuria occurred in 11% and 6% in DTC and HCC, respectively. In RCC, proteinuria occurred in 31% of patients receiving LENVIMA + everolimus (8% grade 3). Monitor for proteinuria prior to initiation and periodically during treatment. If urine dipstick proteinuria ≥2+ is detected, obtain a 24-hour urine protein. Withhold and resume at reduced dose upon recovery or permanently discontinue based on severity.',
        ],
    },
    {
        name: 'Diarrhea',
        items: [
            'Of the 737 LENVIMA-treated patients in DTC and HCC, diarrhea occurred in 49% (6% grade 3). In RCC, diarrhea occurred in 81% of LENVIMA + everolimus–treated patients (19% grade 3). Diarrhea was the most frequent cause of dose interruption/reduction, and diarrhea recurred despite dose reduction. Promptly initiate management of diarrhea. Withhold and resume at reduced dose upon recovery or permanently discontinue based on severity.'
        ],
    },
    {
        name: 'Fistula Formation and Gastrointestinal Perforation',
        items: [
            `Of the 799 patients treated with LENVIMA or LENVIMA + everolimus in DTC, RCC, and HCC, fistula or gastrointestinal perforation occurred in 2%. Permanently discontinue in patients who develop gastrointestinal perforation of any severity or grade 3-4 fistula.`
        ],
    },
    {
        name: 'QT Interval Prolongation',
        items: [
            'In DTC, QT/QTc interval prolongation occurred in 9% of LENVIMA-treated patients and QT interval prolongation of >500 ms occurred in 2%. In RCC, QTc interval increases of >60 ms occurred in 11% of patients receiving LENVIMA + everolimus and QTc interval >500 ms occurred in 6%. In HCC, QTc interval increases of >60 ms occurred in 8% of LENVIMA-treated patients and QTc interval >500 ms occurred in 2%.',

            'Monitor and correct electrolyte abnormalities at baseline and periodically during treatment. Monitor electrocardiograms in patients with congenital long QT syndrome, congestive heart failure, bradyarrhythmias, or those who are taking drugs known to prolong the QT interval, including Class Ia and III antiarrhythmics. Withhold and resume at reduced dose upon recovery based on severity.'
        ],
    },
    {
        name: 'Hypocalcemia',
        items: [
            'In DTC, grade 3-4 hypocalcemia occurred in 9% of LENVIMA-treated patients. In 65% of cases, hypocalcemia improved or resolved following calcium supplementation with or without dose interruption or dose reduction. In RCC, grade 3-4 hypocalcemia occurred in 6% of LENVIMA + everolimus–treated patients. In HCC, grade 3 hypocalcemia occurred in 0.8% of LENVIMA-treated patients. Monitor blood calcium levels at least monthly and replace calcium as necessary during treatment. Withhold and resume at reduced dose upon recovery or permanently discontinue depending on severity.'
        ],
    },
    {
        name: 'Reversible Posterior Leukoencephalopathy Syndrome (RPLS)',
        items: [
            'Across clinical studies of 1,823 patients who received LENVIMA as a single agent, RPLS occurred in 0.3%. Confirm diagnosis of RPLS with MRI. Withhold and resume at reduced dose upon recovery or permanently discontinue depending on severity and persistence of neurologic symptoms.'
        ],
    },
    {
        name: 'Hemorrhagic Events',
        items: [
            'Serious including fatal hemorrhagic events can occur with LENVIMA. In DTC, RCC, and HCC clinical trials, hemorrhagic events, of any grade, occurred in 29% of the 799 patients treated with LENVIMA as a single agent or in combination with everolimus. The most frequently reported hemorrhagic events (all grades and occurring in at least 5% of patients) were epistaxis and hematuria. In DTC, grade 3-5 hemorrhage occurred in 2% of LENVIMA-treated patients, including 1 fatal intracranial hemorrhage among 16 patients who received LENVIMA and had CNS metastases at baseline. In RCC, grade 3-5 hemorrhage occurred in 8% of LENVIMA + everolimus–treated patients, including 1 fatal cerebral hemorrhage. In HCC, grade 3-5 hemorrhage occurred in 5% of LENVIMA-treated patients, including 7 fatal hemorrhagic events. Serious tumor-related bleeds, including fatal hemorrhagic events, occurred in LENVIMA-treated patients in clinical trials and in the postmarketing setting. In postmarketing surveillance, serious and fatal carotid artery hemorrhages were seen more frequently in patients with anaplastic thyroid carcinoma (ATC) than other tumors. Safety and effectiveness of LENVIMA in patients with ATC have not been demonstrated in clinical trials.',

            'Consider the risk of severe or fatal hemorrhage associated with tumor invasion or infiltration of major blood vessels (eg, carotid artery). Withhold and resume at reduced dose upon recovery or permanently discontinue based on severity.'
        ],
    },
    {
        name: 'Impairment of Thyroid Stimulating Hormone Suppression/Thyroid Dysfunction',
        items: [
            'LENVIMA impairs exogenous thyroid suppression. In DTC, 88% of patients had baseline thyroid stimulating hormone (TSH) level ≤0.5 mU/L. In patients with normal TSH at baseline, elevation of TSH level >0.5 mU/L was observed post baseline in 57% of LENVIMA-treated patients. In RCC and HCC, grade 1 or 2 hypothyroidism occurred in 24% of LENVIMA + everolimus–treated patients and 21% of LENVIMA-treated patients, respectively. In patients with normal or low TSH at baseline, elevation of TSH was observed post baseline in 70% of LENVIMA-treated patients in HCC and 60% of LENVIMA + everolimus–treated patients in RCC.',

            'Monitor thyroid function prior to initiation and at least monthly during treatment. Treat hypothyroidism according to standard medical practice.',
        ],
    },
    {
        name: 'Impaired Wound Healing',
        items: [
            'Impaired wound healing has been reported in patients who received LENVIMA. Withhold LENVIMA for at least 1 week prior to elective surgery. Do not administer for at least 2 weeks following major surgery and until adequate wound healing. The safety of resumption of LENVIMA after resolution of wound healing complications has not been established.',
        ],
    },
    {
        name: 'Osteonecrosis of the Jaw (ONJ)',
        items: [
            `ONJ has been reported in patients receiving LENVIMA. Concomitant exposure to other risk factors, such as bisphosphonates, denosumab, dental disease or invasive dental procedures, may increase the risk of ONJ.<br/><br/>
            Perform an oral examination prior to treatment with LENVIMA and periodically during LENVIMA treatment. Advise patients regarding good oral hygiene practices and to consider having preventive dentistry performed prior to treatment with LENVIMA and throughout treatment with LENVIMA.<br/><br/>
            Avoid invasive dental procedures, if possible, while on LENVIMA treatment, particularly in patients at higher risk. Withhold LENVIMA for at least 1 week prior to scheduled dental surgery or invasive dental procedures, if possible. For patients requiring invasive dental procedures, discontinuation of bisphosphonate treatment may reduce the risk of ONJ.<br/><br/>
            Withhold LENVIMA if ONJ develops and restart based on clinical judgement of adequate resolution.`,
        ],
    },
    {
        name: 'Embryo-Fetal Toxicity',
        items: [
            'Based on its mechanism of action and data from animal reproduction studies, LENVIMA can cause fetal harm when administered to pregnant women. In animal reproduction studies, oral administration of LENVIMA during organogenesis at doses below the recommended clinical doses resulted in embryotoxicity, fetotoxicity, and teratogenicity in rats and rabbits. Advise pregnant women of the potential risk to a fetus; and advise females of reproductive potential to use effective contraception during treatment with LENVIMA and for 30 days after the last dose.',
        ],
    },
    {
        name: 'Adverse Reactions',
        items: [
            `In RCC, the most common adverse reactions (≥20%) observed in LENVIMA + KEYTRUDA&ndash;treated patients were fatigue (63%), diarrhea (62%), musculoskeletal pain (58%), hypothyroidism (57%), hypertension (56%), stomatitis (43%), decreased appetite (41%), rash (37%), nausea (36%), decreased weight (30%), dysphonia (30%), proteinuria (30%), palmar-plantar erythrodysesthesia syndrome (29%), abdominal pain (27%), hemorrhagic events (27%), vomiting (26%), constipation (25%), hepatotoxicity (25%), headache (23%), and acute kidney injury (21%).<br/><br/>
            Fatal adverse reactions occurred in 4.3% of patients receiving LENVIMA in combination with KEYTRUDA, including cardio-respiratory arrest (0.9%), sepsis (0.9%), and one case (0.3%) each of arrhythmia, autoimmune hepatitis, dyspnea, hypertensive crisis, increased blood creatinine, multiple organ dysfunction syndrome, myasthenic syndrome, myocarditis, nephritis, pneumonitis, ruptured aneurysm and subarachnoid hemorrhage.<br/><br/>
            Serious adverse reactions occurred in 51% of patients receiving LENVIMA and KEYTRUDA. Serious adverse reactions in ≥2% of patients were hemorrhagic events (5%), diarrhea (4%), hypertension (3%), myocardial infarction (3%), pneumonitis (3%), vomiting (3%), acute kidney injury (2%), adrenal insufficiency (2%), dyspnea (2%), and pneumonia (2%).<br/><br/>
            Permanent discontinuation of LENVIMA, KEYTRUDA, or both due to an adverse reaction occurred in 37% of patients; 26% LENVIMA only, 29% KEYTRUDA only, and 13% both drugs. The most common adverse reactions (≥2%) leading to permanent discontinuation of LENVIMA, KEYTRUDA, or both were pneumonitis (3%), myocardial infarction (3%), hepatotoxicity (3%), acute kidney injury (3%), rash (3%), and diarrhea (2%).<br/><br/>
            Dose interruptions of LENVIMA, KEYTRUDA, or both due to an adverse reaction occurred in 78% of patients receiving LENVIMA in combination with KEYTRUDA. LENVIMA was interrupted in 73% of patients and both drugs were interrupted in 39% of patients. LENVIMA was dose reduced in 69% of patients. The most common adverse reactions (≥5%) resulting in dose reduction or interruption of LENVIMA were diarrhea (26%), fatigue (18%), hypertension (17%), proteinuria (13%), decreased appetite (12%), palmar-plantar erythrodysesthesia (11%), nausea (9%), stomatitis (9%), musculoskeletal pain (8%), rash (8%), increased lipase (7%), abdominal pain (6%), vomiting (6%), increased ALT (5%), and increased amylase (5%).`,

            `In endometrial carcinoma, the most common adverse reactions (≥20%) observed in LENVIMA + KEYTRUDA&ndash;treated patients were hypothyroidism (67%), hypertension (67%), fatigue (58%), diarrhea (55%), musculoskeletal disorders (53%), nausea (49%), decreased appetite (44%), vomiting (37%), stomatitis (35%), decreased weight (34%), abdominal pain (34%), urinary tract infection (31%), proteinuria (29%), constipation (27%), headache (26%), hemorrhagic events (25%), palmar-plantar erythrodysesthesia (23%), dysphonia (22%), and rash (20%).<br/><br/>
            Fatal adverse reactions among these patients occurred in 4.7% of those treated with LENVIMA and KEYTRUDA, including 2 cases of pneumonia, and 1 case of the following: acute kidney injury, acute myocardial infarction, colitis, decreased appetite, intestinal perforation, lower gastrointestinal hemorrhage, malignant gastrointestinal obstruction, multiple organ dysfunction syndrome, myelodysplastic syndrome, pulmonary embolism, and right ventricular dysfunction.<br/><br/>
            Serious adverse reactions occurred in 50% of these patients receiving LENVIMA and KEYTRUDA. Serious adverse reactions with frequency ≥3% were hypertension (4.4%), and urinary tract infection (3.2%).<br/><br/>
            Discontinuation of LENVIMA due to an adverse reaction occurred in 26% of these patients. The most common (≥1%) adverse reactions leading to discontinuation of LENVIMA were hypertension (2%), asthenia (1.8%), diarrhea (1.2%), decreased appetite (1.2%), proteinuria (1.2%), and vomiting (1.2%).<br/><br/>
            Dose reductions of LENVIMA due to adverse reactions occurred in 67% of patients. The most common (≥5%) adverse reactions resulting in dose reduction of LENVIMA were hypertension (18%), diarrhea (11%), palmar-plantar erythrodysesthesia syndrome (9%), proteinuria (7%), fatigue (7%), decreased appetite (6%), asthenia (5%), and weight decreased (5%).<br/><br/>
            Dose interruptions of LENVIMA due to an adverse reaction occurred in 58% of these patients. The most common (≥2%) adverse reactions leading to interruption of LENVIMA were hypertension (11%), diarrhea (11%), proteinuria (6%), decreased appetite (5%), vomiting (5%), increased alanine aminotransferase (3.5%), fatigue (3.5%), nausea (3.5%), abdominal pain (2.9%), weight decreased (2.6%), urinary tract infection (2.6%), increased aspartate aminotransferase (2.3%), asthenia (2.3%), and palmar-plantar erythrodysesthesia (2%).`

        ],
    },
    {
        name: 'Use in Specific Populations',
        items: [
            'Because of the potential for serious adverse reactions in breastfed children, advise women to discontinue breastfeeding during treatment and for 1 week after last dose. LENVIMA may impair fertility in males and females of reproductive potential.',

            'No dose adjustment is recommended for patients with mild (creatinine clearance [CLcr] 60-89 mL/min) or moderate (CLcr 30-59 mL/min) renal impairment. LENVIMA concentrations may increase in patients with DTC, RCC, or endometrial carcinoma and severe (CLcr 15-29 mL/min) renal impairment. Reduce the dose for patients with DTC, RCC, or endometrial carcinoma and severe renal impairment. There is no recommended dose for patients with HCC and severe renal impairment. LENVIMA has not been studied in patients with end stage renal disease.',

            'No dose adjustment is recommended for patients with HCC and mild hepatic impairment (Child-Pugh A). There is no recommended dose for patients with HCC with moderate (Child-Pugh B) or severe (Child-Pugh C) hepatic impairment. No dose adjustment is recommended for patients with DTC, RCC, or endometrial carcinoma and mild or moderate hepatic impairment. LENVIMA concentrations may increase in patients with DTC, RCC, or endometrial carcinoma and severe hepatic impairment. Reduce the dose for patients with DTC, RCC, or endometrial carcinoma and severe hepatic impairment.'
        ],
    },
]};

const globalSafetyFootnotes =
    {keytruda: 'pMMR = mismatch repair proficient; MSI-H = microsatellite instability-high; ALT = alanine aminotransferase.',
    lenvima: 'QTc = corrected QT interval; MRI = magnetic resonance imaging; CNS = central nervous system; ALT = alanine aminotransferase.'};

const globalPreview = {keytruda: '<p><b>Severe and Fatal Immune-Mediated Adverse Reactions:</b> KEYTRUDA is a monoclonal antibody that belongs to a class of drugs that bind to either the programmed death receptor-1 (PD&#8288;-&#8288;1) or the programmed death ligand 1 (PD&#8288;-&#8288;L1), blocking the PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 pathway, thereby removing inhibition of the immune response, potentially breaking peripheral tolerance and inducing immune-mediated adverse reactions. Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or tissue, can affect more than one body system simultaneously, and can occur at any time after starting treatment or after discontinuation of treatment. Important immune-mediated adverse reactions listed here may not include all possible severe and fatal immune-mediated adverse reactions.</p>', lenvima: '<p><b>Hypertension:</b> In differentiated thyroid cancer (DTC), hypertension occurred in 73% of patients on LENVIMA (44% grade 3-4). In advanced renal cell carcinoma (RCC), hypertension occurred in 42% of patients on LENVIMA + everolimus (13% grade 3). Systolic blood pressure ≥160 mmHg occurred in 29% of patients, and 21% had diastolic blood pressure ≥100 mmHg. In unresectable hepatocellular carcinoma (HCC), hypertension occurred in 45% of LENVIMA-treated patients (24% grade 3). Grade 4 hypertension was not reported in HCC.</p>'};

export default {
    list : globalSafetyList,
    footnotes : globalSafetyFootnotes,
    preview : globalPreview
}