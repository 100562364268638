import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { rightIconMulberry, leftIconMulberry } from '../../util/icons';
import { ANCHORNAVBUTTONS } from '../../constants/z-index-map';

const AnchorNavContainer = styled.div`
    background: ${colors.white};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 0;
    height: 52px;
    position: absolute;
    top: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const AnchorNavScrollableArea = styled.div`
    overflow-x: scroll;
    height: 52px;
    width: 100%;

    /* hides scrollbar on chrome */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const NavLine = styled.hr`
    height: 3px;
    width: 100%;
    background: linear-gradient(96deg, ${colors.mulberry} 47.42%, ${colors.mulberryLight} 100%);
    margin: 0;
    position: absolute;
    border: 0;
    &:nth-of-type(1) {
        top: 0;
    }
    &:nth-of-type(2) {
        bottom: 0;
    }
`;

const AnchorNavContent = styled.div`
    max-width: 1032px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.ink};
    padding-top: 15px;
    .keytruda {
        color: ${colors.brandGreen};
    }
    .lenvima {
        color: ${colors.mulberry};
    }
`;

const ArrowButton = styled.button`
    background: ${colors.mist};
    width: 25px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: ${ANCHORNAVBUTTONS};
    border: 0;
    top: 0px;
    cursor: pointer;
    img {
        width: 7px;
        height: 12px;
    }
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
`;

const AnchorLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 52px;
    & > * {
        position: relative;
        text-wrap: nowrap;
        white-space: nowrap;
        text-decoration: none;
        color: ${colors.ink};
        &:after {
            content: '|';
            position: absolute;
            right: -30px;
            color: ${colors.pebble};
            letter-spacing: -0.54px;
        }
        &:first-child{
            padding-left: 26px;
        }
        &:last-child{
            padding-right: 26px;
        }
        &:last-child:after {
            content: none;
        }
        &:visited {
            &.keytruda{
                color: ${colors.brandGreen};
            }
            &.lenvima{
                color: ${colors.mulberry};
            }
            color: ${colors.ink};
        }
        &:hover {
            text-decoration: underline;
            text-decoration-color: ${colors.ink};
            &.keytruda{
                text-decoration-color: ${colors.brandGreen};
            }
            &.lenvima{
                text-decoration-color: ${colors.mulberry};
            }
        }
    }
    ${respondTo.md`
        justify-content: center;
        gap: 60px;
        padding: 0 30px;
    `}
`;

const AnchorNav = ({children, className}) => {

    const anchorRef = useRef(null);
    const [isLeftHidden, setIsLeftHidden] = useState(false);
    const [isRightHidden, setIsRightHidden] = useState(false);

    function listenToResize(e) {
        setIsLeftHidden(anchorRef.current.scrollLeft > 0);
        setIsRightHidden(anchorRef.current.scrollWidth > anchorRef.current.scrollLeft + anchorRef.current.clientWidth + 5);
    }

    useEffect(() => {
        listenToResize();
        window.addEventListener("resize", listenToResize);
        anchorRef.current.addEventListener("scroll", listenToResize);
        return () => {
           window.removeEventListener("resize", listenToResize);
           anchorRef.current.removeEventListener("scroll", listenToResize);
        }
      }, []);

    return (
        <AnchorNavContainer>
            <AnchorNavScrollableArea ref={anchorRef}>
                <NavLine />
                <AnchorNavContent className={className}>
                    {isLeftHidden && <ArrowButton className='left' onClick={() => {anchorRef.current.scrollLeft -= 30; listenToResize();} }>
                        <img src={leftIconMulberry} alt='' />
                    </ArrowButton>}
                    <AnchorLinks>
                        {children}
                    </AnchorLinks>
                    {isRightHidden && <ArrowButton className='right' onClick={() => {anchorRef.current.scrollLeft += 30; listenToResize();} }>
                        <img src={rightIconMulberry} alt='' />
                    </ArrowButton>}
                </AnchorNavContent>
                <NavLine />
            </AnchorNavScrollableArea>
        </AnchorNavContainer>
)};
export default AnchorNav;
