import React from 'react';
import styled from 'styled-components';
import { indicationsData, getCancerTypeIdFromIndicationId, createIndicationListEntryByCancerType } from './data';
import * as colors from '../../util/colors';

import * as ISI from './styled';
import { createMarkup } from '../../util/functions';
import { BodyStyle } from '../../util/typography';
import { respondTo } from '../../util/breakpoints';

//Just prior to launch, PRT mandated that in the in-page and drawer, the indications HAD to be formatted like a bulleted list. Rather than reformat the paragraph content in util/data/indications.js (which is used everywhere else), we use CSS here to fake the look
const IndicationsListContent = styled.div`
    p {
        color: ${colors.charcoal};
        ${BodyStyle};
        ${respondTo.md`
            padding-right: 20px;
        `}
    }
    
`;

const IndicationsList = ({ columns = 1, scrollable_ref, indicationId }) => {
    //Because there will often be cases where we need to launch a 'standalone indication page' before the full content has been approved for global elements, we will pull content for 'singular indications' directly from the global array (rather than the list created in ./data.js).
    let indicationsListByCancerType = [];
    if (indicationId) {
        const cancerTypeId = getCancerTypeIdFromIndicationId(indicationId);
        indicationsListByCancerType = [createIndicationListEntryByCancerType(cancerTypeId)];
    } else {
        indicationsListByCancerType = indicationsData;
    }

    return (
        <ISI.InformationListContainer ref={scrollable_ref}>
            {indicationsListByCancerType
                .filter(item => (indicationId) ? item.indicationIds.includes(indicationId) : item)
                .map((item, i) => {
                let indicationsContent = item.items
                .filter(indication => (!indication.isCombination))
                .map((child, i) => child.statement);

                return (

                    <ISI.InformationItem columns={columns} key={i}>
                        <header>
                            <ISI.InformationItemLabel dangerouslySetInnerHTML={createMarkup(item.modifierAndName)}>
                                
                            </ISI.InformationItemLabel>
                        </header>
                        
                        <IndicationsListContent dangerouslySetInnerHTML={createMarkup(indicationsContent.join(''))} />
                    </ISI.InformationItem>
            )})}
        </ISI.InformationListContainer>
    );
};

export default IndicationsList;
