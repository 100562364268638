// Primary color palette
export const brandGreen = '#48A548';
export const mulberry = '#870051';

// Secondary color palette
export const greenDark = '#2C6D3C';
export const greenLight = '#6CC04A';
export const mulberryLight = '#D10373';
export const greenRollOver = '#23472C';
export const greenBackground = '#E7F8E0';
export const mulberryBackground = '#FBF1F3';
export const mulberryHover = '#3C0024';
export const greenGradient = '#5FC323';
export const mulberryLine = '#70204F';

// Neutral color palette
export const ink = '#262626';
export const charcoal = '#444444';
export const slate = '#696969';
export const concrete = '#8C8C8C';
export const pebble = '#BEBEBE';
export const silver = '#DDDDDD';
export const mist = '#EBEAEB';
export const cloud = '#F3F3F3';
export const white = '#FFFFFF';
export const lightGrey = '#C4C4C4';

// State color palette
export const error = '#DB3933';
export const warning = '#FFE105';
export const focus = '#006EAD';
