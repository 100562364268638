export const ARE_YOU_AN_HCP_MODAL_OVERLAY = 50;
export const GENERAL_MODAL_OVERLAY = 49;

export const NAV_CONTAINER = 10;
export const NAV_CONTAINER_MOBILE_MENU_OPEN = 46;

export const DESKTOP_NAV_OVERLAY = 5;
export const MOBILE_NAV_OVERLAY = 45;

export const INDICATIONS_AND_SAFETY_CONTAINER = 40;
export const INDICATIONS_DRAWER_LAUNCH_BUTTON = 41;
export const INDICATIONS_INFORMATION_DRAWER = 42;
export const SAFETY_DESKTOP_DRAWER_LAUNCH_BUTTON = 43;
export const SAFETY_DESKTOP_INFORMATION_DRAWER = 44;
export const SAFETY_KEYTRUDA_DRAWER_LAUNCH_BUTTON = 43;
export const SAFETY_KEYTRUDA_INFORMATION_DRAWER = 44;
export const SAFETY_LENVIMA_DRAWER_LAUNCH_BUTTON = 45;
export const SAFETY_LENVIMA_INFORMATION_DRAWER = 46;

export const STYLED_OVERLAY = 9;

export const INDICATIONS_AND_SAFETY_DRAWER_OVERLAY = 13;

export const NAV_HEADER = 10;
export const UTILITY_NAV_MENU = 13;
export const MOBILE_MENU = 46;
export const GLOBAL_NAV_MENU = 12;
export const PAGE_HEADER = 11;

export const ANCHORNAVBUTTONS = 30;
export const MEGAMENU = 40;

export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_BOTTOM = 45;
export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_MIDDLE = 46;
export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_TOP = 47;
