import styled from 'styled-components';
import { Body } from '../../util/typography';
import * as colors from '../../util/colors';
import { respondTo } from '../../util/breakpoints';

export const InformationListContainer = styled.section`
    overflow: auto;
    padding-bottom: 30px;
    color: ${colors.charcoal};
    ${respondTo.md`
    
    `}
`;

export const InformationItemLabel = styled(Body)`
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
    color: ${colors.charcoal};

    em {
        font-style: normal;
        text-decoration: underline;
        font-weight: 400;
    }
`;

export const InformationItem = styled.article`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;

    &:last-child {
        padding-bottom: 0;
    }

    ${({ columns }) =>
        columns === 2 &&
        `
        header {
            flex: 1 1 280px;
            padding: 0 20px 0 0;
            margin-bottom: 10px;
        }

        > div {
            flex: 23 1 280px;
            min-width: 280px;
            padding: 0;
        }
    `}
`;
