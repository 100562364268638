import styled, { css } from 'styled-components';
import { respondTo } from '../breakpoints';
import * as colors from '../colors';
import { Link } from 'gatsby';
import dashIcon from '../../assets/content-icons/icon-dash.svg';

// Headings
//----

const baseHeadings = ({fontSize, largeFontSize, lineHeight, largeLineHeight, fontWeight, largeFontWeight, letterSpacing, largeLetterSpacing, textTransform, fontStyle, color}) => css`
  font-size: ${fontSize};
  line-height: ${lineHeight || 'calc(1em + 4px)'};
  font-weight: ${fontWeight || '700'};
  letter-spacing: ${letterSpacing};
  text-transform: ${textTransform || 'none'};
  font-style: ${fontStyle || 'normal'};
  ${color && `color: ${color}`};

  ${respondTo.md`
    font-size: ${largeFontSize};
    line-height: ${largeLineHeight};
    letter-spacing: ${largeLetterSpacing};
    font-weight: ${largeFontWeight};
  `}
`;


export const Xl = styled.p`
  ${baseHeadings({
    fontSize: '40px',
    largeFontSize: '60px',
    lineHeight: '42px',
    largeLineHeight: '64px',
    fontWeight: 400,
  })}
`;

export const H1 = styled.h1`
  ${baseHeadings({
    fontSize: '30px',
    largeFontSize: '50px',
    lineHeight: '32px',
    largeLineHeight: '56px',
    fontWeight: 400,
  })}
`;

export const H2 = styled.h2`
  ${baseHeadings({
    fontSize: '26px',
    largeFontSize: '40px',
    lineHeight: '28px',
    largeLineHeight: '44px',
    fontWeight: 400,
  })};
`;

export const H3 = styled.h3`
  ${baseHeadings({
    fontSize: '24px',
    largeFontSize: '30px',
    lineHeight: '26px',
    largeLineHeight: '36px',
    fontWeight: 400,
  })}
`;

export const H4 = styled.h4`
  ${baseHeadings({
    fontSize: '22px',
    largeFontSize: '24px',
    lineHeight: '24px',
    largeLineHeight: '26px',
    fontWeight: 400,
  })}
`;

export const H5 = styled.h5`
  ${baseHeadings({
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 400,
  })}
`;

export const H6 = styled.h6`
  ${baseHeadings({
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 700,
  })}
`;

export const H1Style = css`
  ${baseHeadings({
    fontSize: '30px',
    largeFontSize: '50px',
    lineHeight: '32px',
    largeLineHeight: '56px',
    fontWeight: 400,
  })}
`;

export const H2Style = css`
  ${baseHeadings({
    fontSize: '26px',
    largeFontSize: '40px',
    lineHeight: '28px',
    largeLineHeight: '44px',
    fontWeight: 400,
  })};
`;

export const H3Style = css`
  ${baseHeadings({
    fontSize: '24px',
    largeFontSize: '30px',
    lineHeight: '26px',
    largeLineHeight: '36px',
    fontWeight: 400,
  })}
`;

export const H4Style = css`
  ${baseHeadings({
    fontSize: '22px',
    largeFontSize: '24px',
    lineHeight: '24px',
    largeLineHeight: '26px',
    fontWeight: 400,
  })}
`;

export const H4DesktopStyle = css`
  ${baseHeadings({
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 400,
  })}
`;

export const H6Style = css`
  ${baseHeadings({
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 700,
  })}
`;

export const Subtitle = styled.h6`
  ${baseHeadings({
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 400,
  })}
`;

export const Body = styled.p`
  ${baseHeadings({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  })}
`;


export const BodyStyle = css`
  ${baseHeadings({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  })}
`;

export const LinkTag = styled(Link)`
  ${baseHeadings({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: colors.greenDark
  })}
`;

export const LinkStyle = css`
  ${baseHeadings({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  })};
  cursor: pointer;
`;

export const FootnoteStyle = css`
  ${baseHeadings({
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  })}
`;

export const Reference = styled.div`
  ${FootnoteStyle};
  p {
    display: flex;
  }
  cite {
    font-style: italic;
    line-height: 16px;
  }
  [class*='__Acronym']{
    margin-top: 8px;
    display: block;
  }
`;

export const Footnote = styled.ul`
  ${FootnoteStyle};
  list-style-type: lower-alpha;
  padding-left: 16px;
  li {
    padding-left: 14px;
    margin-bottom: 8px;
  }
`;

export const Acronym = styled.p`
  ${FootnoteStyle};
  display: block;
`;

export const Ol = styled.ol`
  ${BodyStyle};
  padding-left: 16px;
  li {
    margin-bottom: 10px;
    padding-left: 14px;
    &:last-child {
        margin-bottom: 0px;
    }
  }
  ol {
    list-style-type: lower-alpha;
    padding-left: 30px;
  }
`;

export const Ul = styled.ul`
  ${BodyStyle};
  padding-left: 13px;
  list-style-type: none;
  li {
        padding-left: 3px;
        margin-bottom: 10px;
        position: relative;
        &:last-child {
            margin-bottom: 0px;
        }
        &::before {
          display: inline-block;
          content: '';
          -webkit-border-radius: 50%;
          border-radius: 50%;
          height: 4px;
          width: 4px;
          position: absolute;
          left: -10px;
          top: 8px;
          transform: translateX(-50%);
          ${({marker}) => marker && `background-color: ${marker};`}
      }
    }
    ul {
        list-style-type: none;
        position: relative;
        padding-left: 12px;
        li {
          padding-left: 10px;
          position: relative;
        }
    }
    ul li::before {
        content: '';
        left: -3px;
        border-radius: 0;
        height: 1.5px;
        width: 8px;
    }
    ul + li {
      margin-top: 10px;
    }
`;

export const KeyLink = styled(Link)`
    ${LinkStyle};
    text-decoration-line: underline;
    color: ${colors.greenDark};
    &:hover {
        color: ${colors.greenRollOver};
    }
`;

export const LenLink = styled(Link)`
    ${LinkStyle};
    color: ${colors.mulberry};
    text-decoration-line: underline;
    &:hover {
        color: ${colors.mulberryHover};
    }
`;

export const Bold = styled.p`
  ${LinkStyle};
  color: ${colors.ink};
`;

export const UtilityBar = css`
  ${baseHeadings({
    fontSize: '12px',
    fontWeight: 400,
  })}
`;

export const Legal = css`
  ${baseHeadings({
    fontSize: '10px',
    fontWeight: 400,
  })}
`;

export const DesktopOnly = styled.span`
  display: none;
  ${respondTo.md`
    display: inline;
  `}
`;

export const MobileOnly = styled.span`
  display: inline;
  ${respondTo.md`
    display: none;
  `}
`;

export const MobileMenuStyle = css`
  font-family: Arial;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const HeaderH5Style = css`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const AccordionTitleStyle = css`
  ${baseHeadings({
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '22px',
    largeLineHeight: '24px'
  })}
`;

export const IndicationTitle = styled.h2`
  ${baseHeadings({
    fontSize: '22px',
    largeFontSize: '24px',
    fontWeight: 700,
    lineHeight: '26px',
    largeLineHeight: '30px'
  })}
`;

export const PreviewTitleStyle = css`
  ${baseHeadings({
    fontSize: '18px',
    largeFontSize: '20px',
    fontWeight: 700,
    lineHeight: '18px',
    largeLineHeight: '21px'
  })}
`;

export const HeaderH6 = styled.h6`
  ${baseHeadings({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  })}
`;

export const TableSpan = styled.span`
  ${baseHeadings({
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
  })}
`;

export const EndoTitle = css`
  ${baseHeadings({
    fontSize: '30px',
    largeFontSize: '38px',
    fontWeight: 400,
    lineHeight: '32px',
    largeLineHeight: '44px'
  })}
`;