import { createAction } from '@reduxjs/toolkit';

export const setState = createAction('SET_STATE');

export const openHcpAcknowledgement = createAction('OPEN_HCP_ACK');
export const closeHcpAcknowledgement = createAction('CLOSE_HCP_ACK');

export const openGeneralModal = createAction('OPEN_GEN_MOD');
export const closeGeneralModal = createAction('CLOSE_GEN_MOD');

export const openIndicationDrawerPreview = createAction('OPEN_IND_DRAWER_PREVIEW');
export const minimizeIndicationDrawerPreview = createAction('MINIMIZE_IND_DRAWER_PREVIEW');
export const toggleIndicationDrawer = createAction('TOGGLE_IND_DRAWER');
export const toggleSafetyDrawerDesktop = createAction('TOGGLE_SAFETY_DRAWER_DESKTOP');
export const toggleSafetyKeytrudaDrawer = createAction('TOGGLE_SAFETY_KEYTRUDA_DRAWER');
export const toggleSafetyLenvimaDrawer = createAction('TOGGLE_SAFETY_LENVIMA_DRAWER');

export const openOverlay = createAction('OPEN_OVERLAY');
export const closeOverlay = createAction('CLOSE_OVERLAY');

export const toggleFilter = createAction('TOGGLE_FILTER');
export const openFilter = createAction('OPEN_FILTER');
export const closeFilter = createAction('CLOSE_FILTER');
export const setFilteredCancerTypes = createAction('SET_FILTER_CANCER_TYPES');

export const setMainNav = createAction('SET_MAIN_NAV');
export const openMenuItem = createAction('OPEN_MENU_ITEM');
export const closeAllMenuItems = createAction('CLOSE_ALL_MENU_ITEMS');
export const openMobileMenu = createAction('OPEN_MOBILE_MENU');
export const closeMobileMenu = createAction('CLOSE_MOBILE_MENU');
export const indicateMainNavIsPinned = createAction('MAINNAV_PINNED');
export const indicateMainNavIsNotPinned = createAction('MAINNAV_UNPINNED');
export const indicateScrolledPastInPageSSI = createAction('SCROLLED_PAST_IN_PAGE_SSI');
export const indicateNotScrolledPastInPageSSI = createAction('NOT_SCROLLED_PAST_IN_PAGE_SSI');

export const allowScrolling = createAction('DISABLE_SCROLL_LOCK');
export const preventScrolling = createAction('ENABLE_SCROLL_LOCK');

export const setUsableSpace = createAction('SET_USABLE_SPACE');
