import React from "react";
import styled from 'styled-components';
import { respondTo } from "../../util/breakpoints";
import a11y from "../../assets/logo-a11y.png";
import merckLogo from "../../assets/merck-logo.png";
import eisaiLogo from "../../assets/eisai-logo-desktop.png";
import * as colors from '../../util/colors';
import {  UtilityBar, FootnoteStyle, DesktopOnly, MobileOnly } from '../../util/typography';
import { Link } from "gatsby";

const TopFooter = styled.div`
  background: ${colors.charcoal};
`;

const LinkWrap = styled.div`
  position: relative;
  max-width: 1032px;
  margin: 0 auto;
  padding: 28px 20px;
  ${respondTo.md`
    padding: 18px 26px;
  `}
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: ${colors.charcoal};
    padding-left: 0;

    ${respondTo.md`
      justify-content: center;
      padding: 4px 10px;
      gap: 15px;
      width: fit-content;
      flex-direction: row;
      margin: 0 auto;
      position: relative;
  `}
    
  }
  li {
    list-style: none;
    a, a:visited {
      color: ${colors.white};
      text-decoration: none;
      cursor: pointer;
    }
    background: ${colors.charcoal};
    ${UtilityBar};
    line-height: 14px;
    font-size: 14px;
    ${respondTo.md`
      font-size: 12px;
      line-height: 12px;
    `}
  }
`;

const LinkLine = styled.hr`
  position: absolute;
  border-bottom: 1px solid ${colors.lightGrey};
  top: 18px;
  width: calc(100% - 54px);
  display: none;

  ${respondTo.md`
      display: block;
  `}
`;

const BotFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 118px;
  flex-direction: column;
  gap: 20px;
  max-width: 1032px;
  margin: 0 auto;
  ${FootnoteStyle}

  ${respondTo.md`
      flex-direction: row;
      padding: 26px;
  `}
`;

const BotFooterSection = styled.div`
  width: 100%;
  color: ${colors.ink};
  line-height: 14px;
  ${respondTo.md`
      width: unset;
      max-width: 497px;
  `}
`;

const LogoTray = styled(BotFooterSection)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 0;
  img, div {
    height: 45px;
  }
  ${respondTo.md`
    flex-direction: row;
    gap: 0;
    padding: 0;
    img, div {
      height: unset;
    }
  `}
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: row;
  img, div {
    height: 45px;
  }

  ${respondTo.md`
      margin-left: auto;
      gap: 36px;
      justify-content: end;
      img, div {
        height: 35px;
      }
  `}
`;

const Jobcode = styled.div`
  margin-top: 10px;
`;

export default function Footer({jobCode}) {

  return (
    <footer>
     <TopFooter>
      <LinkWrap>
      <LinkLine/>
        <ul>
          <li>
            <a href="https://www.msdprivacy.com/us/en/" target='_blank'>Privacy Policy</a>
          </li>
          <li>
            <a href="https://www.merck.com/terms-of-use/" target='_blank'>Terms of Use</a>
          </li>
          <li>
            <Link to='/sitemap'>Site Map</Link>
          </li>
          <li>
            <a className="ot-sdk-show-settings">Cookie Preferences</a>
          </li>
          <li>
            <a href="https://www.msdaccessibility.com/" target='_blank'>Accessibility</a>
          </li>
        </ul>
      </LinkWrap>
     </TopFooter>
     <BotFooter>
      <BotFooterSection>
        <p>
        This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved. The trademarks used are owned by their respective owners.
        </p>
        <Jobcode>{jobCode}</Jobcode>
      </BotFooterSection>
      <LogoTray>
        <MobileOnly><a href="https://www.levelaccess.com/a/merck/" target='_blank'><img width={120} alt='Level Access Company Logo' src={a11y}/></a></MobileOnly>
        <LogoWrap>
          <DesktopOnly><a href="https://www.levelaccess.com/a/merck/" target='_blank'><img width={93} src={a11y} alt='Level Access Company Logo'/></a></DesktopOnly>
          <DesktopOnly><img width={113} src={merckLogo} alt='Merck Logo'/></DesktopOnly>
          <MobileOnly><img width={154} src={merckLogo} alt='Merck Logo'/></MobileOnly>
          <DesktopOnly><img width={55} src={eisaiLogo} alt='Eisai Logo'/></DesktopOnly>
          <MobileOnly><img width={75} src={eisaiLogo} alt='Eisai Logo'/></MobileOnly>
        </LogoWrap>
      </LogoTray>
     </BotFooter>
    </footer>
  );
}