import React, {useRef} from 'react';
import GlobalStyles from '../styles';
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import store from '../store';
import { Provider } from 'react-redux';
import IndicationAndSafetyDrawer from '../components/IndicationAndSafety/IndicationAndSafetyDrawer';
import IndicationAndSafety from '../components/IndicationAndSafety';
import HCPAcknowledgement from '../components/HCPAcknowledgement';
import ScrollLockObserver from '../components/ScrollLockObserver';
import GeneralModal from '../components/ExternalModal';


function DefaultLayout({ children, pageMeta, location, name, icon, iconAlt = '', anchorNavLinks, filterIndicationDrawer, safetyDataOverride, indicationId, className, jobCode}) {

    let launch_button_container_ref = useRef(null);
    let hcp_acknowledgement_ref = useRef(null);
    let inline_indications_ref = useRef(null);
    let general_modal_ref = useRef(null);
    
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                <title>{pageMeta ? pageMeta.title : ''}</title>
                <meta name="description" content={pageMeta ? pageMeta.description : ''} />
                <meta name="keywords" content={pageMeta ? pageMeta.keywords : ''} />
                <link rel="canonical" href={`${location ? location.pathname : ''}`} />
                { pageMeta && pageMeta.noindex && (
                    <meta name="robots" content="noindex" />
                )}
                {/* Google Tag Manager */}
                <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-K3ZXNLB');`}</script>
                {/* End Google Tag Manager */}

                {/* OneTrust Cookies Consent  */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={
                        process.env.GATSBY_PRODUCTION_BUILD
                            ? 'bd1318f1-a02b-4bce-98f7-d08eaf90d3da'
                            : 'bd1318f1-a02b-4bce-98f7-d08eaf90d3da-test'
                    }
                ></script>
                <script type="text/javascript">{`function OptanonWrapper() { }`}</script>
                {/* OneTrust Cookies Consent end */}

                {/* dataLayer initialization for properly capturing page url on entry */}
                <script type="text/javascript">{`
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({
                        event: "initial_page_load",
                        page_url: window.location.href.replace(window.location.origin, '')
                    });
                `}</script>
                {/* end dataLayer initialization for properly capturing page url on entry */}
                {/* begin JSON-LD page info */}
                {/* Becuase Gatsby + Helmet are like helicopter parents, we can't just plop the 
                all the schema tags in one chunk. So we have to do it in this ugly way  */}
                {pageMeta && pageMeta.schemaJsonLD && pageMeta.schemaJsonLD.map(script => <script type="application/ld+json">{script}</script>)}                
                {/* begin JSON-LD */}
            </Helmet>
            {/* Keep global styles outside the Provider, they should never re-render */}
            <GlobalStyles />
            <Provider store={store}>
                <div className={className}>
                    <ScrollLockObserver />
                    <NavBar location={location} name={name} anchorNavLinks={anchorNavLinks} icon={icon} iconAlt={iconAlt}/>

                    <main>{children}</main>

                    <IndicationAndSafety inline_indications_ref={inline_indications_ref} indicationId={indicationId} safetyDataOverride={safetyDataOverride}/>

                    <Footer jobCode={jobCode}/>

                    <HCPAcknowledgement hcp_acknowledgement_ref={hcp_acknowledgement_ref} />
                    <GeneralModal general_modal_ref={general_modal_ref} />
                    <IndicationAndSafetyDrawer
                        inline_indications_ref={inline_indications_ref}
                        launch_button_container_ref={launch_button_container_ref}
                        hcp_acknowledgement_ref={hcp_acknowledgement_ref}
                        indicationId={filterIndicationDrawer ? indicationId : undefined}
                    />
                </div>
            </Provider>


        </>
    );
}

export default DefaultLayout;
