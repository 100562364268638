import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { LinkStyle } from '../../util/typography';
import arrowIcon from '../../assets/utility/icons/icon-arrow-right-circle.svg';
import externalIcon from '../../assets/utility/icons/icon-external-circle.svg';
import downloadIcon from '../../assets/utility/icons/icon-download-circle.svg';
import { Link } from 'gatsby';
import { splitGradient } from '../../util/functions';

// TO-DO: All button styles should have default animations on hover

const ButtonContainer = styled.button`
    padding: ${({noIcon}) => noIcon ? `12px 20px` : `12px 16px`};
    border-radius: 4px;
    width: fit-content;
    border: 0;
    background: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 8px;
    ${LinkStyle};
    img {
        width: 20px;
        height: 20px;

        &.userIcon {
            width: 16px;
            height: auto;
        }
    }
    &.primary-dark {
        background: ${splitGradient(colors.greenDark, colors.greenRollOver)};
        transition: background-position 0.3s ease-in-out;
        background-position: left center;
        background-size: 201% 100%;
        background-origin: border-box;
        color: ${colors.white};

        &:disabled {
            background: ${colors.slate};
            cursor: default;
        }
        &:focus {
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
        }
        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);
        }
        @media (hover:hover) {
            &:hover,
            &:active,
            &:focus {
                background-position: right center;
            }
        }
    }
    &.primary-light {
        background: ${colors.white};
        color: ${colors.greenDark};
        img {
            filter: invert(44%) sepia(9%) saturate(3327%) hue-rotate(82deg) brightness(73%) contrast(80%);
        }
        &:hover {
            background: ${colors.mulberryLight};
            color: ${colors.white};
        }
        &:disabled {
            background: ${colors.slate};
            color: ${colors.white};
            cursor: default;
        }
        &:focus {
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
            color: ${colors.greenDark};
        }
        &:hover, &:disabled {
            img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);
            }
        }
    }
    &.secondary-dark {
        color: ${colors.greenDark};
        border: 2px solid ${colors.greenDark};
        img {
            filter: invert(44%) sepia(9%) saturate(3327%) hue-rotate(82deg) brightness(73%) contrast(80%);
        }
        &:hover {
            background: ${colors.greenDark};
            color: ${colors.white};
            img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);
            }
        }
        &:disabled {
            border: 2px solid ${colors.slate};
            color: ${colors.slate};
            cursor: default;
            img {
                filter: invert(43%) sepia(0%) saturate(0%) hue-rotate(210deg) brightness(90%) contrast(80%);
            }
        }
        &:focus {
            background: ${colors.greenDark};
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
            color: ${colors.white};
        }
    }
    &.secondary-light {
        color: ${colors.white};
        border: 2px solid ${colors.white};
        img {
            filter: invert(99%) sepia(0%) saturate(166%) hue-rotate(161deg) brightness(118%) contrast(100%);
        }
        &:hover {
            background: ${colors.white};
            color: ${colors.greenDark};
            img {
                filter: invert(34%) sepia(32%) saturate(847%) hue-rotate(82deg) brightness(93%) contrast(85%);
            }
        }
        &:disabled {
            border: 2px solid ${colors.silver};
            color: ${colors.silver};
            cursor: default;
            img {
                filter: invert(85%) sepia(0%) saturate(3%) hue-rotate(28deg) brightness(105%) contrast(93%);
            }
        }
        &:focus {
            background: ${colors.white};
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
            color: ${colors.greenDark};
        }
    }
    &.tertiary-dark {
        padding: 10px 0;
        display: flex;
        gap: 8px;
        color: ${colors.greenDark};
        border-radius: 0;
        img {
            filter: invert(44%) sepia(9%) saturate(3327%) hue-rotate(82deg) brightness(73%) contrast(80%);
        }
        &:hover{
            color: ${colors.greenRollOver};
            img {
                filter: invert(21%) sepia(18%) saturate(1179%) hue-rotate(83deg) brightness(98%) contrast(90%);
            }
        }
        &:disabled {
            color: ${colors.slate};
            cursor: default;
            img {
                filter: invert(77%) sepia(0%) saturate(29%) hue-rotate(210deg) brightness(102%) contrast(87%);
            }
        }
        &:focus {
            border-radius: 4px;
            background: ${colors.white};
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
       }
    }
    &.tertiary-light {
        padding: 10px 0;
        display: flex;
        gap: 8px;
        color: ${colors.white};
        border-radius: 0;
        img {
            filter: invert(99%) sepia(2%) saturate(338%) hue-rotate(12deg) brightness(120%) contrast(100%);
        }
        &:hover{
            color: ${colors.greenRollOver};
            img {
                filter: invert(19%) sepia(29%) saturate(886%) hue-rotate(83deg) brightness(96%) contrast(85%);
            }
        }
        &:disabled {
            color: ${colors.pebble};
            cursor: default;
            img {
                filter: invert(74%) sepia(22%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(84%);
            }
        }
        &:focus {
            border-radius: 4px;
            background: ${colors.greenDark};
            box-shadow: 0px 0px 4px 4px rgba(131, 210, 255, 0.60);
       }
    }
`;

const Button = ({children, type, className, disabled, icon, to, noIcon, href, ...props}) => {

    const linkAttribute = to ? {
        to : to
    } : href  ? {
        href : href
    } : '';

    return (
        <ButtonContainer as={to ? Link : href ? 'a' : 'button'} {...linkAttribute} className={`${className} ${type}`} disabled={disabled} noIcon={noIcon} {...props}>
            {children}
            {!icon && !noIcon && <img src={arrowIcon} alt='' />}
            {icon === 'external' && <img src={externalIcon} alt='' />}
            {icon === 'download' && <img src={downloadIcon} alt='' />}
            {icon !== 'external' && icon !== 'download' && icon && <img src={icon} className='userIcon' alt='' />}
        </ButtonContainer>
)};
export default Button;
