import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SCROLL_LOCK_CLASSNAME } from '../../styles/_general';

/**
 * This component is so we can hook scroll-locking into redux.
 * This component doesn't render anything to screen, it is purely
 * for effect purposes.
 */
export default function ScrollLockObserver() {
    const scrollLock = useSelector(state => state.scrollLock);
    
    useEffect(() => {
        /**
         * 
         */
        if (document?.children) {
            if (scrollLock) {
                document.children[0].classList.add(SCROLL_LOCK_CLASSNAME);
            } else if (!scrollLock) {
                document.children[0].classList.remove(SCROLL_LOCK_CLASSNAME);
            }
        }
    }, [scrollLock]);

    return null;
}
