import React from 'react';
import styled from 'styled-components';
import { DesktopOnly, IndicationTitle, MobileOnly } from '../../util/typography';

import SafetyList from './SafetyList';
import IndicationsList from './IndicationsList';
import * as colors from '../../util/colors';
import { respondTo } from '../../util/breakpoints';

const Container = styled.div`
    height: 100%;
    max-width: 1032px;
    padding: 0 20px 20px 20px;
    margin: 0 auto;
    > section {
        > header {
            margin-bottom: 9px;
        }
    }
    keytitle {
        color: ${colors.brandGreen};
    }
    lentitle {
        color: ${colors.mulberry};
    }
    ${respondTo.md`
        padding: 0px 30px 0 30px;
    `}
`;

const Columns = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
    [class*='__IndicationTitle']{
        line-height: 24px;
        margin-bottom: 10px;
        ${respondTo.md`
            line-height: 26px;
        `}
    }
    [class*='__InformationItem']{
        padding-bottom: 0;
    }
    [class*='__InformationListContainer']{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    ${respondTo.md`
        flex-direction: row;
        gap: 40px;
    `}
`;

const Column1 = styled.div`
    width: 100%;
    position: relative;
    a, a:visited {
        color: ${colors.brandGreen};
    }
    [class*='__InformationListContainer']{
        padding-bottom: 0;
        ${respondTo.md`
            padding-bottom: 30px;
        `}
    }
    
    ${respondTo.md`
        width: calc(50% - 20px);
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -20px;
            width: 1px;
            background: ${colors.pebble};
            height: calc(100% - 30px);
        }
    `}
`;
const Column2 = styled.div`
    width: 100%;
    position: relative;
    a, a:visited {
        color: ${colors.mulberry};
    }
    [class*='__InformationListContainer']{
        padding-bottom: 0;
    }
    ${respondTo.md`
        width: calc(50% - 20px);
        padding: 0 10px;
        [class*='__InformationListContainer']{
            padding-bottom: 30px;
        }
    `}
`;

const SsiAnchor = styled.div`
    position: absolute;
    top: -150px;
`;

function IndicationAndSafetyPage({ inline_indications_ref, indicationId, safetyDataOverride }) {
    return (
        <Container>
            <section>
                <header>
                    <IndicationTitle>Indications for <keytitle>KEYTRUDA®&nbsp;(pembrolizumab)</keytitle> +  <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></IndicationTitle>
                </header>
                <div>
                    <IndicationsList indicationId={indicationId} />
                </div>
            </section>
            <Columns>
                <Column1>
                    <SsiAnchor id='ssi-keytruda' />
                    <header ref={inline_indications_ref}>
                        <IndicationTitle><keytitle>Selected Safety Information for KEYTRUDA®<MobileOnly> </MobileOnly><DesktopOnly>&nbsp;</DesktopOnly>(pembrolizumab)</keytitle></IndicationTitle>
                    </header>
                    <div>
                        <SafetyList safetyDataOverride={safetyDataOverride} type='keytruda'/>
                    </div>
                </Column1>
                <Column2>
                    <SsiAnchor id='ssi-lenvima' />
                        <header>
                            <IndicationTitle><lentitle>Selected Safety Information for LENVIMA®&nbsp;(lenvatinib)</lentitle></IndicationTitle>
                        </header>
                    <div>
                        <SafetyList safetyDataOverride={safetyDataOverride} type='lenvima'/>
                    </div>
                </Column2>
            </Columns>
        </Container>
    );
}

export default IndicationAndSafetyPage;
