import { createGlobalStyle } from 'styled-components';
import fonts from '../fonts/proxima-nova-font-faces.css';
import { GeneralStyles, NormalizeGlobalStyle, OneTrustOverrides } from './_general';


const GlobalStyles = createGlobalStyle`
  ${NormalizeGlobalStyle}
  ${fonts}
  ${GeneralStyles}
  ${OneTrustOverrides}
`;


export default GlobalStyles;