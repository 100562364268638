import { configureStore } from '@reduxjs/toolkit';

import rootReducer, { INITIAL_STATE } from './reducers/root';
import {SELECTED_CANCER_TYPES_KEY} from './storage-keys';

const initializeState = () => {
     //if the app was reloaded, we lose the local store, so check for the cancerType selections
     if (typeof window !== 'undefined' && window.location.protocol !== 'data:' && window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY)) {
        INITIAL_STATE.filteredCancerTypes = JSON.parse(window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY));
    }
    return INITIAL_STATE;
}

const store = configureStore({
    reducer: rootReducer,

    /**
     * @note If we ever move our root reducer to utilize `combineReducers`,
     * we'll need to keep the shape of the initial state to be the same
     * as our final reducer.
     */
    preloadedState: initializeState(),
});

export default store;
