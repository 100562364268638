import React from 'react';
import styled from 'styled-components';
import globalSafetyData from './data/globalSafety';
import { Reference, Ul, Body, HeaderH5Style } from '../../util/typography';
import * as colors from '../../util/colors';
import * as ISI from './styled';

import { createMarkup } from '../../util/functions';
// import * as List from '../../util/typography/List';

const DrawerContentTitle = styled(Body)`
    margin: 5px 0 5px 0;
    display: block;
    font-style: italic;
    color: ${colors.charcoal};
`;
const DrawerList = styled(Ul)`
    margin-top: 0;
    margin-bottom: 15px;
    color: ${colors.charcoal};
`;

const FootnoteContainer = styled.div`
`;

const SafetyListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const SSIFooter = styled.h5`
    ${HeaderH5Style};

    a {
        &:hover {
            color: ${colors.greenDark};
        }
    }
    .lenvima {
        &:hover {
            color: ${colors.mulberryHover};
        }
    }
`;

const SafetyListItem = ({ item, type }) => {
    if (item.items) {
        return (
            <>
                {item.items.map((subitem, i) => (
                    <li key={i} dangerouslySetInnerHTML={createMarkup(subitem)} />
                ))}
            </>
        );
    } else {
        return <li dangerouslySetInnerHTML={createMarkup(item)} />;
    }
};


const SafetyList = ({ columns = 1, scrollable_ref, safetyDataOverride, type }) => {

    const data = safetyDataOverride || globalSafetyData;

    return (
        <ISI.InformationListContainer ref={scrollable_ref}>
            {data.list[type].map((item, i) => {
                return (
                    <ISI.InformationItem columns={columns} key={i}>
                        <header>
                            <ISI.InformationItemLabel dangerouslySetInnerHTML={createMarkup(item.name)} />
                        </header>
                        <SafetyListWrapper>
                            {item.items.map((child, i) => {
                                return (
                                    <div key={i}>
                                        {child.name && (
                                            <DrawerContentTitle dangerouslySetInnerHTML={createMarkup(child.name)} />
                                        )}

                                        <DrawerList marker={type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : 'inherit'}>
                                            <SafetyListItem item={child} type={type} />
                                        </DrawerList>
                                    </div>
                                );
                            })}
                        </SafetyListWrapper>
                    </ISI.InformationItem>
                );
            })}

            <FootnoteContainer>
                <Reference dangerouslySetInnerHTML={createMarkup(data.footnotes[type])} />
            </FootnoteContainer>
            <footer>
                {type === 'keytruda' ? <SSIFooter>
                    Before prescribing KEYTRUDA®&nbsp;(pembrolizumab), please read the accompanying{' '}
                    <a
                        data-design-category="content_link_vd"
                        href="https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf"
                        target="_blank"
                    >
                        Prescribing Information
                    </a>
                    . The{' '}
                    <a
                        data-design-category="content_link_vd"
                        href="https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Medication Guide
                    </a>{' '}
                    also is available.
                </SSIFooter>
                    :
                    <SSIFooter>
                        Before prescribing LENVIMA®&nbsp;(lenvatinib), please read the accompanying{' '}
                        <a
                            data-design-category="content_link_vd"
                            href="https://www.lenvima.com/-/media/Project/EISAI/Lenvima/PDF/prescribing-information.pdf"
                            target="_blank"
                            className='lenvima'
                        >
                            Prescribing&nbsp;Information and Patient&nbsp;Information</a>.
                    </SSIFooter>}
            </footer>
        </ISI.InformationListContainer>
    );
};

export default SafetyList;
